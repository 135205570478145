//
// Stepper Links
//

// Base
.stepper.stepper-links {
    .stepper-nav {
		display: flex;
		margin: 0 auto;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

        .stepper-item {
			position: relative;
			flex-shrink: 0;
			margin: 1rem 1.5rem;

			&:after {
				content: " ";
				position: absolute;
				top: 2.3rem;
				left: 0;
				height: 2px;
				width: 100%;
				background-color: transparent;
				transition: $transition-link;
			}

            .stepper-title {
                color: var(--kt-dark);
                font-weight: 600;
                font-size: 1.25rem;
            }

            // Current
            &.current {
				transition: $transition-link;

				.stepper-title {
					color: var(--kt-primary);
				}

				&:after {
					background-color: var(--kt-primary);
				}
			}

			&.current.mark-completed:last-child,
			&.completed {
				.stepper-title {
					color: var(--kt-gray-400);
				}
			}
        }
    }
}
