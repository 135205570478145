//
// Aside Default
//

// General mode(all devices)
.docs-aside {
	display: flex;
	flex-direction: column;
	box-shadow: var(--kt-docs-aside-box-shadow);
	background-color: var(--kt-docs-aside-bg-color);
	padding: 0;
}

.docs-aside-select-menu {
	width: 218px;
}

// Desktop mode
@include media-breakpoint-up(lg) {
	.docs-aside {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: $docs-aside-z-index;
		overflow: hidden;
		width: $docs-aside-width;

		// Logo
		.docs-aside-logo {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 $docs-aside-padding-x;
		}

		// Aside menu
		.docs-aside-menu {
			width: $docs-aside-width;
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.docs-aside {
		display: none;

		// Logo
		.docs-aside-logo {
			display: none;
		}
	}
}
