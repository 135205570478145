//
// Layout Config
//

// Content background Color
$docs-page-bg-color: $gray-100;
$docs-page-bg-color-dark: #151521;

// Content Spacing
$docs-content-spacing-desktop: 30px;
$docs-content-spacing-tablet-and-mobile: 15px;

// Aside
$docs-aside-z-index: 101;
$docs-aside-padding-x: 25px;
$docs-aside-menu-indention: 0.75rem;
$docs-aside-width: 265px;
$docs-aside-bg-color: white;
$docs-aside-bg-color-dark: #1e1e2d;
$docs-aside-box-shadow: 0 0 28px 0 rgba(82,63,105,.025);
$docs-aside-box-shadow-dark: none;
